import classnames from "classnames"
import React from "react"

const Haha = ({ className, ...props }) => (
  <div className={classnames("emoji emoji--haha", className)} {...props}>
    <div className="emoji__face">
      <div className="emoji__eyes" />
      <div className="emoji__mouth">
        <div className="emoji__tongue" />
      </div>
    </div>
  </div>
)

export default Haha
