import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import IconFacebook from "../../../../assets/svg/campaigns/countdown/facebook.svg"
import IconLinkedIn from "../../../../assets/svg/campaigns/countdown/linkedin.svg"
import IconMessenger from "../../../../assets/svg/campaigns/countdown/messenger.svg"
import IconPinterest from "../../../../assets/svg/campaigns/countdown/pinterest.svg"
import IconTwitter from "../../../../assets/svg/campaigns/countdown/twitter.svg"
import IconWhatsApp from "../../../../assets/svg/campaigns/countdown/whatsapp.svg"

const Share = () => {
  const intl = useIntl()

  return (
    <section className="share py-10 py-lg-12">
      <Container>
        <Row>
          <Col lg={5} className="pb-14 mb-8 mb-lg-0 pb-lg-0">
            <IconFacebook
              style={{ position: "absolute", top: "10%", left: " 15%", width: 100, height: 100 }}
            />
            <IconLinkedIn style={{ position: "absolute", bottom: "0%", left: "5%" }} />
            <IconPinterest
              style={{ position: "absolute", top: "20%", right: "15%", width: 80, height: 80 }}
            />
            <IconTwitter
              style={{ position: "absolute", bottom: "0%", right: "40%", width: 90, height: 90 }}
            />
            <IconWhatsApp
              style={{ position: "absolute", top: "5%", left: "45%", width: 60, height: 60 }}
            />
            <IconMessenger style={{ position: "absolute", bottom: "0%", right: "15%" }} />
          </Col>
          <Col lg={7}>
            <h1
              className="heading display-3"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage:
                    'Easy to <b>share</b> across all <b class="text-primary">social media</b> channels',
                }),
              }}
            />
            <p className="description mb-0">
              {intl.formatMessage({
                defaultMessage:
                  "The countdown page offers share buttons for your visitors with an optimized post for each channel.",
              })}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Share
