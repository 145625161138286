import classnames from "classnames"
import React from "react"

const Yay = ({ className, ...props }) => (
  <div className={classnames("emoji emoji--yay", className)} {...props}>
    <div className="emoji__face">
      <div className="emoji__eyebrows" />
      <div className="emoji__mouth" />
    </div>
  </div>
)

export default Yay
