import classnames from "classnames"
import React from "react"

const Love = ({ className, ...props }) => (
  <div className={classnames("emoji emoji--love", className)} {...props}>
    <div className="emoji__heart" />
  </div>
)

export default Love
