import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Button from "react-bootstrap/Button"

const LeadGeneration = ({ campaign }) => {
  const intl = useIntl()

  return (
    <form
      name="lead"
      method="post"
      action="/thanks"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <div className="d-md-flex mx-md-n2">
        <input
          id="eventLink"
          name="eventLink"
          type="text"
          className="form-control mb-4 mb-md-0 mx-md-2"
          placeholder={intl.formatMessage({ defaultMessage: "Eventbrite Link" })}
          required
        />
        <input
          id="email"
          name="email"
          type="email"
          className="form-control mb-4 mb-md-0 mx-md-2"
          placeholder={intl.formatMessage({ defaultMessage: "Email" })}
          required
        />
        <Button type="submit" className="text-nowrap mx-md-2">
          {intl.formatMessage({ defaultMessage: "Get started" })}
        </Button>
      </div>
      <input type="hidden" name="campaign" value={campaign} />
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="lead" />
    </form>
  )
}

export default LeadGeneration
