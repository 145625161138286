import classnames from "classnames"
import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import ReactCountdownNow from "react-countdown-now"
import "./scss/index.scss"

const CountdownItem = ({ time, singular, plural }) => (
  <div className="px-2">
    <span className="countdown__time d-block mb-1">{time}</span>
    <small className="countdown__text d-block">{time === 1 ? singular : plural}</small>
  </div>
)

const Countdown = ({ className }) => {
  const intl = useIntl()

  return (
    <ReactCountdownNow
      date={Date.now() + 930000000}
      renderer={({ days, hours, minutes, seconds }) => (
        <div className={classnames("countdown d-flex justify-content-between mx-n2", className)}>
          <CountdownItem
            time={days}
            singular={intl.formatMessage({ defaultMessage: "Day" })}
            plural={intl.formatMessage({ defaultMessage: "Days" })}
          />
          <CountdownItem
            time={hours}
            singular={intl.formatMessage({ defaultMessage: "Hour" })}
            plural={intl.formatMessage({ defaultMessage: "Hours" })}
          />
          <CountdownItem
            time={minutes}
            singular={intl.formatMessage({ defaultMessage: "Minute" })}
            plural={intl.formatMessage({ defaultMessage: "Minutes" })}
          />
          <CountdownItem
            time={seconds}
            singular={intl.formatMessage({ defaultMessage: "Second" })}
            plural={intl.formatMessage({ defaultMessage: "Seconds" })}
          />
        </div>
      )}
    />
  )
}

export default Countdown
