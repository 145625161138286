import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import ImageFacebookPost from "../../../../assets/images/campaigns/countdown/facebook-post.png"
import Countdown from "../Countdown"
import { EmojiHaha, EmojiLike, EmojiLove, EmojiWow, EmojiYay } from "./Emoji"
import "./scss/emojis.scss"
import "./scss/index.scss"

const Facebook = () => {
  const intl = useIntl()

  return (
    <section className="facebook pt-14 pb-10 py-lg-12" style={{ backgroundColor: "#f6f8fb" }}>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg={8}>
            <h1
              className="heading display-3"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage:
                    'Ready-to-use <b>post</b> for your <b style="color: #3a5898">facebook</b> event page.',
                }),
              }}
            />
            <p className="description col-lg-10 mx-auto mb-8 px-0">
              {intl.formatMessage({
                defaultMessage:
                  "With just one click you can share your countdown with your facebook community.",
              })}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <Countdown className="countdown col-md-10 col-lg-8 mx-auto rounded text-center p-4 mb-8" />
            <Card className="position-relative shadow-lg">
              <Card.Body className="p-4">
                <Image src={ImageFacebookPost} fluid />
              </Card.Body>
              <div>
                <EmojiLike style={{ right: -40, bottom: 60 }} />
                <EmojiHaha style={{ right: -60, bottom: 80 }} />
                <EmojiLove style={{ right: -80, bottom: 100 }} />
                <EmojiWow style={{ right: -90, bottom: 120 }} />
                <EmojiYay style={{ right: -110, bottom: 90 }} />
                <EmojiWow style={{ right: -85, bottom: 60 }} />
                <EmojiLike style={{ right: -80, bottom: 130 }} />
                <EmojiHaha style={{ right: -120, bottom: 120 }} />
                <EmojiLove style={{ right: -140, bottom: 120 }} />
                <EmojiWow style={{ right: -160, bottom: 140 }} />
                <EmojiYay style={{ right: -180, bottom: 160 }} />
                <EmojiLike style={{ right: -140, bottom: 150 }} />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Facebook
