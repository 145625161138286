import { useIntl } from "gatsby-plugin-intl"
import React, { useEffect, useState } from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { CSSTransition } from "react-transition-group"
import { COUNTDOWN } from "../../../../../campaign-config"
import IconArrowDown from "../../../../assets/svg/icons/arrow-down.svg"
import Countdown from "../Countdown"
import LeadGeneration from "../LeadGeneration/"
import "./scss/index.scss"

const Hero = () => {
  const intl = useIntl()
  const [scrollY, setScrollY] = useState(0)

  function updateScrollY() {
    setScrollY(window.pageYOffset)
  }

  useEffect(() => {
    window.addEventListener("scroll", updateScrollY)

    return () => {
      window.removeEventListener("scroll", updateScrollY)
    }
  })

  return (
    <section className="hero position-relative">
      <Container className="pt-8 pt-md-13 pb-14">
        <Row>
          <Col md={7} className="text-white">
            <h1
              className="heading display-2"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage: "Get your <b>free</b> and <b>customized</b> event countdown.",
                }),
              }}
            />
            <p className="description">
              {intl.formatMessage({
                defaultMessage:
                  "Post it on your facebook event page to re-engage your potential customers.",
              })}
            </p>
          </Col>
          <Col md={5}>
            <div className="w-md-120 img-skewed img-skewed-left">
              <Card className="img-skewed-item shadow-lg text-center mt-md-n8">
                <Card.Img
                  variant="top"
                  className="img-fluid"
                  // src="https://images.unsplash.com/photo-1505842465776-3b4953ca4f44?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
                  src="https://images.unsplash.com/photo-1562179322-8aad7c6220f7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
                />
                <Card.Body>
                  <Card.Title as="h3" className="mb-2">
                    {intl.formatMessage({ defaultMessage: "Winter Festival 2019" })}
                  </Card.Title>
                  <p className="text-secondary mb-6">
                    {intl.formatMessage({ defaultMessage: "08. December 2019" })}
                  </p>
                  <div
                    className="rounded-bottom border-top px-6 py-4 mx-n6 mb-n6"
                    style={{ backgroundColor: "#f6f8fb" }}
                  >
                    <Countdown className="col-9 px-0 mx-auto" />
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
        <CSSTransition in={scrollY <= 200} timeout={350} classNames="fade" unmountOnExit>
          <div className="hero__scroll-down d-none d-md-block text-center text-white mb-12">
            <IconArrowDown className="bounce" />
          </div>
        </CSSTransition>
      </Container>
      <Container>
        <Card className="hero__lead-generation col-md-10 shadow px-0 mb-n12 mb-md-n7">
          <Card.Body className="p-4">
            <LeadGeneration campaign={COUNTDOWN} />
          </Card.Body>
        </Card>
      </Container>
    </section>
  )
}

export default Hero
