import classnames from "classnames"
import React from "react"

const Wow = ({ className, ...props }) => (
  <div className={classnames("emoji emoji--wow", className)} {...props}>
    <div className="emoji__face">
      <div className="emoji__eyebrows" />
      <div className="emoji__eyes" />
      <div className="emoji__mouth" />
    </div>
  </div>
)

export default Wow
