import classnames from "classnames"
import React from "react"

const Like = ({ className, ...props }) => (
  <div className={classnames("emoji emoji--like", className)} {...props}>
    <div className="emoji__hand">
      <div className="emoji__thumb" />
    </div>
  </div>
)

export default Like
