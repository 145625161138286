import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Helmet from "react-helmet"
import Facebook from "../../components/campaigns/countdown/Facebook"
import Hero from "../../components/campaigns/countdown/Hero"
import "../../components/campaigns/countdown/scss/index.scss"
import Share from "../../components/campaigns/countdown/Share"
import Footer from "../../components/Footer"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

const Countdown = () => {
  const intl = useIntl()

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "campaign-countdown",
        }}
      >
        <link rel="stylesheet" href="https://use.typekit.net/kho5gmu.css" />
      </Helmet>
      <SEO title={intl.formatMessage({ defaultMessage: "Your Event Countdown" })} />
      <Layout clean>
        <Hero />
        <Facebook />
        <Share />
        <Footer disableCallToAction style={{ backgroundColor: "#f6f8fb" }} />
      </Layout>
    </>
  )
}

export default Countdown
